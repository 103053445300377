<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Distributor</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <distributor-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" />
                <distributor-form ref="distributorForm" :reload="reload"/> 
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import distributorGrid from '../Distibutor/Grid/DistributorGrid.vue';
import distributorForm from '../Distibutor/Component/DistributorForm.vue';
import distributorServices from './Script/DistributorServices';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Distributor',
    components: {
        'distributor-grid': distributorGrid,
        'distributor-form': distributorForm
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Distributor');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.distributorForm.addClick();
        },
        editClick(id, view){
            this.$refs.distributorForm.editClick(id, view);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    distributorServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await distributorServices.statusButtonGenerator(['New', 'Active', 'Inactive'], "Distributor");

            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('N');
                });
                $("#btnActive").on('click', function() {
                    vue.$refs.grid.changeStatus('A');
                });
                $("#btnInactive").on('click', function() {
                    vue.$refs.grid.changeStatus('I');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>